<template>
  <div class="list-wrap">
    <div
      class="comment-list"
      v-if="data && data.length">
      <div
        v-for="(item, index) in contractData(data)"
        :key="index"
        class="list-item"
        @click="$emit('onRowClick', item)">
        <div class="item-left">
          <div class="item-img">
            <img :src="item.imgUrl"/>
          </div>
          <div class="item-info">
            <div class="item-content">
              {{item.content}}
            </div>
            <div class="item-floor">
              <span style="margin-right: 16px;">{{item.menuName}}</span>
              <span style="margin-right: 16px;" v-if="item.menuGroupName && item.menuGroupName.length">{{item.menuGroupName}}</span>
              <span>{{$t('moment.create', {user: item?.createdUser?.nickName})}}</span>
            </div>
          </div>
        </div>
        <div class="list-right" style="cursor: default;" @click.stop>
          <div class="list-created">
            {{toDate(item.created)}}
          </div>
        </div>
      </div>
    </div>
    <el-empty v-else />
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import dayjs from 'dayjs'
export default defineComponent({
  name: 'Dashboard',
  components: {
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })

    const toDate = (date) => {
      return dayjs.unix(date).format('MM-DD HH:mm')
    }

    const contractData = (data) => {
      const res = data.map(i => {
        let obj = {}
        try {
          if (i.parameters) {
            obj = JSON.parse(i.parameters)
          }
        } catch (error) {
          console.error(error)
        }
        return {
          ...i,
          ...obj
        }
      })
      return res
    }
    return {
      ...toRefs(state),
      toDate,
      contractData
    }
  }
})
</script>
<style lang='less' scoped>
.list-wrap {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .list-title {
        font-size: 16px;
        padding: 16px 0;
        padding-left: 16px;
        border-bottom: solid 1px rgba(0,0,0,0.1);
    }
    .comment-list {
        overflow: auto;
        flex: 1;
        .list-item + .list-item {
          border-top: solid 1px rgba(0,0,0,0.1);
        }
        .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* padding-left: 16px; */
            padding: 16px;
            cursor: pointer;
            .item-left {
                display: flex;
                align-items: center;
                flex: 1;
                overflow: hidden;
                .item-img {
                    width: 72px;
                    height: 54px;
                    overflow: hidden;
                    display: inline-flex;
                    justify-content: center;
                    margin-right: 6px;
                }
                .item-info {
                    /* height: 100%; */
                    flex: 1;
                    overflow: hidden;
                    .item-content {
                        line-height: 28px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .item-floor {
                        color: rgba(0,0,0,0.6);
                    }
                }
            }
            .list-right {
                text-align: end;
                .list-icon {
                    font-size: 18px;
                }
                .list-created {
                    color: rgba(0,0,0,0.6);
                }
            }
        }
    }
}
</style>
