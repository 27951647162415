<!--    新版项目列表，原 ProjectList.vue 废弃   -->
<template>
  <!--    外层灰色背景，高度为 100%    -->
  <div class="project-container">
    <!--    中心白色背景，高度为 100%    -->
    <div class="project-content">
      <!--    标题面板    -->
      <ArcoRow justify="space-between">
        <ArcoCol :xs="12" :sm="12" :md="8" :xl="6">
          <div class="title">
            <!--      项目列表      -->
            {{$t('header.projectList')}}
          </div>
        </ArcoCol>

        <ArcoCol :xs="12" :sm="12" :md="8" :xl="6">
          <ArcoSpace>
            <!--      样式选择      -->
            <ArcoRadioGroup v-model:model-value="listType" type="button">
              <!--      网格样式      -->
              <ArcoRadio value="gird">
                <IconApps />
              </ArcoRadio>

              <!--      列表样式      -->
              <ArcoRadio value="list">
                <IconList />
              </ArcoRadio>
            </ArcoRadioGroup>

            <!--    搜索框    -->
            <ArcoInputSearch
              v-model="searchText"
              :placeholder="$t('project.searchProject')"
              allow-clear
              size="large"
              style="border-radius: 40px"
              :max-length="50"
              show-word-limit
            />
          </ArcoSpace>
        </ArcoCol>
      </ArcoRow>

      <MessageListModal/>

      <!--    数据面板 暂无数据    -->
      <ArcoResult v-if="projectList.length === 0" status="404" title="暂无数据">
        <template #icon>
          <img
              :src="imgEmptyData"
              alt=""
              :style="{
              width: '100px',
              height: '100px',
            }"
          />
        </template>
      </ArcoResult>

      <!--    内容区域，高度自适应（gird）    -->
      <ArcoRow v-if="projectList.length !== 0 && listType === 'gird'" class="project-list" :gutter="[16, 16]">
        <!--    列表项    -->
        <ArcoCol v-for="item in projectList" :key="item.projectId" :xs="24" :sm="12" :md="8" :xl="6">
          <div class="project-item" @click="handleItemClick(item)">
            <ArcoImage
                width="100%"
                height="100%"
                :src="item.projectLogo"
                fit="cover"
                :preview="false"
                :style="{
                maxWidth: '100%',
                aspectRatio: 1.5
              }"
            />

            <div class="name"> {{ item.projectName }} </div>
          </div>
        </ArcoCol>
      </ArcoRow>

      <!--    内容区域，高度自适应（list）    -->
      <ArcoTable
        v-if="false"
        class="project-list"
        :columns="columns"
        :pagination="false"
        :data="projectList">
      </ArcoTable>

      <ArcoList
        v-if="projectList.length !== 0 && listType === 'list'"
        class="project-list">
        <ArcoListItem
          v-for="item in projectList"
          :key="item.projectId"
          class="list-project-item"
          @click="handleItemClick(item)">
          <ArcoListItemMeta :title="item.projectName">
            <template #avatar>
              <ArcoImage
                width="50p"
                height="50"
                :src="item.projectLogo"
                fit="cover"
                :preview="false"
              />
            </template>
          </ArcoListItemMeta>
        </ArcoListItem>
      </ArcoList>

    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import api from '@/services/api'
import { useRouter } from 'vue-router'
import imgEmptyData from '@/assets/empty-data.png'
import MessageListModal from '@/views/client/project-list/components/message-list-modal.vue'

// 引入 Arco 组件
import {
  Row as ArcoRow,
  Col as ArcoCol,
  Image as ArcoImage,
  Result as ArcoResult,
  InputSearch as ArcoInputSearch,
  Space as ArcoSpace,
  RadioGroup as ArcoRadioGroup,
  Radio as ArcoRadio,
  Table as ArcoTable,
  List as ArcoList,
  ListItem as ArcoListItem,
  ListItemMeta as ArcoListItemMeta
} from '@arco-design/web-vue'

import {
  IconList,
  IconApps
} from '@arco-design/web-vue/es/icon'

const router = useRouter()

const listType = ref('gird')
const searchText = ref()
// 项目列表 源数据
const originProjectList = ref([])
// 项目列表
const projectList = computed(() => originProjectList.value.filter(x => !searchText.value || x.projectName.includes(searchText.value)))

const columns = ref([
  {
    // 名称
    title: 'image',
    dataIndex: 'image',
    slotName: 'image'
  },
  {
    // 名称
    title: 'name',
    dataIndex: 'name',
    slotName: 'name'
  }
])

/**
 * 获取数据
 */
const fetchData = async () => {
  const { data: { list } } = await api.project.getProjectList({
    orgId: router.currentRoute.value.params.orgId,
    m: 'loc',
    search: searchText.value
  })

  originProjectList.value = list.map(x => {
    return {
      projectId: x.projectId,
      projectLogo: x.logo,
      projectName: x.name,
      projectDesc: x.desc,
      orgId: router.currentRoute.value.params.orgId,
      created: x.created,
      updated: x.updated,
      memberNum: x.userQry
    }
  })
}

fetchData()

/**
 * 列表项点击
 */
const handleItemClick = (item) => {
  router.push({
    name: 'Project',
    params: {
      orgId: item.orgId,
      projectId: item.projectId
    }
  })
}

</script>

<style lang='less' scoped>
.project-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--color-fill-2);
  height: 100%;
  padding-top: 16px;
  overflow: auto;

  .project-content {
    max-width: 1400px;
    width: 100%;
    background: var(--color-bg-1);
    padding: 16px;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 100%;

    .title-panel{
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .title {
      font-size: 20px;
      font-weight: 500;
    }

    .project-list {
      .project-item {
        padding: 16px;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(229, 230, 235, 1);
        display: flex;
        flex-direction: column;
        gap: 16px;
        cursor: pointer;

        .logo {
          height: 100px;
        }

        .name{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 500;
        }

        &:hover{
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }

      .list-project-item{
        cursor: pointer;

        &:hover{
          background: #f2f3f5;
        }
      }
    }
  }
}

// responsive
@media (min-width: 768px) {
  .project-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
