<template>
  <div class="message-list">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="message-item"
      @click="clickMessage(item.ancestorId)">
      <div class="created-user">
        <a-avatar class="user-avatar" :src="item.createdUser.headAvatar"/>
        <div class="created-info">
          <div class="user-info">
            <span class="user-name">
              {{item.createdUser.nickName}}
            </span>
            <span class="action-type">
              {{getActionStr(item.actionType)}}
            </span>
          </div>
          <div class="created-date">
            {{$utils.toTime(item.created)}}
          </div>
        </div>
      </div>
      <div class="message-context">
        {{item.context}}
      </div>
      <div class="message-target">
        <span class="target-type">
          {{`${$store.state.user.userInfo.userId === item.toUserId ? $t('moment.mine') : ''}${getTargetStr(item.targetType)}:`}}
        </span>
        <span class="target-title">
          {{item.title}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'MessageList',
  components: {},
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const { t } = useI18n()
    const state = reactive({

    })
    onMounted(async () => {

    })

    const getActionStr = (v) => {
      let str = ''
      switch (v) {
        case 3:
          str = t('moment.commented')
          break
        case 4:
          str = t('moment.replied')
          break
        case 5:
          str = t('moment.liked')
          break
      }
      return str
    }

    const getTargetStr = (v) => {
      let str = ''
      switch (v) {
        case 1:
          str = t('moment.comment')
          break
        case 2:
          str = t('moment.moment')
          break
      }
      return str
    }

    const clickMessage = (id) => {
      context.emit('clickMessage', id)
    }
    return {
      ...toRefs(state),
      getTargetStr,
      getActionStr,
      clickMessage
    }
  }
})
</script>
<style lang='less' scoped>
.message-list {
  overflow: auto;
  height: 100%;
  .message-item + .message-item {
      margin-top: 18px;
      padding-top: 18px;
      border-top: solid 1px rgba(0,0,0,0.1);
  }
  .message-item {
    cursor: pointer;
    .created-user {
        display: flex;
        .created-info {
            margin-left: 12px;
            .user-info {
              span.user-name {
                  margin-right: 8px;
              }
            }
        }
    }
    .message-context {
        margin: 8px 0;
    }
    .message-target {
        // height: 37px;
        /* line-height: 1px; */
        border-radius: 5px;
        background-color: rgba(238, 238, 238, 60);
        color: rgba(16, 16, 16, 100);
        display: flex;
        align-items: center;
        padding: 8px 16px;
        span.target-type {
          margin-right: 8px;
          white-space: nowrap;
        }
    }

  }
}
</style>
