<template>
  <div class="card-item">
    <div class="card-header">
      <div style="display: flex;">
        <div class="header-avatar">
          <a-avatar :size="46" :src="currentItem.headAvatar ? currentItem.headAvatar : ''">
            {{currentItem?.nickName?.substr(0, 1)}}
          </a-avatar>
        </div>
        <div class="header-info allow-user-select">
          <div class="info-name">
            {{currentItem.nickName}}
          </div>
          <div class="info-date" @click="commentClick">
            {{$utils.toTime(currentItem.createdDate)}}
          </div>
        </div>
      </div>
      <div class="header-project allow-user-select">
        <div v-if="currentItem.projectName">
          <IconFont type='icon-lou' style="margin-right: 20px;"/>
          {{currentItem.projectName}}
        </div>
        <a-tag color="orange" v-if="currentItem.isTop" style="margin-left: 5px;">{{$t('moment.goTop')}}</a-tag>
      </div>
    </div>
    <div class="card-img-list">
      <a-row :gutter="[8,8]" style="width:100%">
        <a-col
          :class="photos.length > 1 && idx < imgMaxLength ? 'img-three-part' : ''"
          :span="photos.length > 1 ? 8 : 18"
          v-for="(img,idx) in photos"
          :key="idx">
          <el-image
            fit="cover"
            lazy
            class="img-item"
            :class="photos.length > 1 ? 'mut-img' : ''"
            v-if="idx < imgMaxLength"
            :src="img.path"
            @click="setVisible(idx)"/>
            <div v-if="(idx === imgMaxLength -1) && ((photos.length - imgMaxLength) > 0)" class="max-img">+{{photos.length - imgMaxLength}}</div>
            <!-- :style="photos.length <= 4 ? 'height: 300px;' : ''" -->
        </a-col>
      </a-row>
    </div>
    <div class="card-title allow-user-select">
      {{currentItem.detailTitle}}
    </div>
    <span
      v-if="currentItem.momentLink"
      @click="jumpLink(currentItem.momentLink)"
      class="card-link">{{currentItem.momentLink?.link.split('|')[0]}}</span>
    <div class="card-tag">
      <span
        class="tag-item"
        v-for="(item, idx) in currentItem.tagList"
        :key="idx"
        @click="tagClick(item.tagId, item.tagName)">
        {{'#' + item.tagName}}
      </span>
    </div>
    <div class="card-action" v-if="isActionShow">
      <div style="display: flex;">
        <div class="action-button" @click="commentClick">
          <message-filled style="margin-right:8px"/>
          <span>{{currentItem.commentQty}}</span>
        </div>
        <div class="action-button" :class="{'button-active': currentItem.isLike}" @click="likeMoment">
          <like-filled style="margin-right:8px"/>
          <span>{{currentItem.likeQty}}</span>
        </div>
      </div>
      <div class="flex">
        <div
          v-if="!isCommentShow"
          class="flex flex-a-i-center card-link"
          @click="commentClick">
          {{$t('moment.getDetail')}}
        </div>
        <!-- <a-dropdown :trigger="['click']" v-if="isCommentShow && (currentItem.createUserId === $store.state.user.userInfo.userId)">
          <div class="action-button" @click.prevent>
            <ellipsis-outlined/>
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0"> -->
                <a-popconfirm
                  :title="$t('moment.confirmDeleteMoment')"
                  @confirm="deleteMoment(currentItem.momentId)"
                   v-if="isCommentShow && (currentItem.createUserId === $store.state.user.userInfo.userId)"
                >
                  <div class="action-button">{{$t('moment.delete')}}</div>
                </a-popconfirm>
              <!-- </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown> -->
      </div>
    </div>
    <div class="card-divider" v-if="isActionShow"/>
    <div class="card-comment" v-if="isActionShow">
      <a-input v-model:value="commentWord" :placeholder="$t('moment.publishComment')" @focus="isFocus = true" @blur="isFocus = false"  />
      <a-upload
        accept=".png,.jpg"
        :showUploadList="false"
        :before-upload="beforeUpload"
        :capture="null"
        >
        <CloudUploadOutlined class="up-icon" />
      </a-upload>
      <a-button
        style="border: 0;"
        :style="isFocus ? `color: #007AFF;background-color: rgba(232, 243, 255, 1);` : 'color: #A1A5B7;background-color: rgba(246, 248, 250, 1);'"
        @click="onComment({moment: currentItem, momentId: currentItem.momentId, context: commentWord, fileList})">
        <template #icon v-if="breakpoint > breakpointList.xs"><SendOutlined /></template>
        {{$t('moment.send')}}<span v-if="breakpoint > breakpointList.xs">{{$t('moment.comment')}}</span>
      </a-button>
    </div>
    <div
      v-if="fileList && fileList.length && !replyId"
      class="flex flex-wrap flex-a-i-center">
      <div v-for="(cImg, imgdx) in fileList" :key="imgdx" style="position: relative;">
        <a-image
          style="object-fit:cover;"
          :width="110"
          :src="cImg.path"
        >
        </a-image>
        <CloseCircleOutlined style="font-size: 20px;color: red;cursor: pointer;position: absolute;top: 0;right: 0;" @click="delmg(imgdx)"/>
      </div>

    </div>
    <div class="card-divider" v-if="isCommentShow"/>
    <div class="card-comment-list" v-if="isCommentShow">
      <a-comment
        v-for="(comment, index) in commentList"
        :key="index">
        <template #author>
          <span class="comment-author">
            {{comment.nickName}}
          </span>
        </template>
        <template #avatar>
          <a-avatar :src="comment.headAvatar">
            {{comment?.nickName?.substr(0, 1)}}
          </a-avatar>
        </template>
        <template #actions>
          <span style="display: none" @click="replyTo(comment)">{{$t('moment.replyComment')}}</span>
        </template>
        <div
          class="card-comment"
          v-if="replyId === comment.commentId"
          :class="breakpoint <= breakpointList.xs ? 'next-row' : ''">
          <a-input v-model:value="replyWord" :placeholder="`${$t('moment.newReply')}...`"/>
          <div style="margin: 8px 0" class="card-divider" v-if="breakpoint <= breakpointList.xs" />
          <div
            style="display: flex; justify-content: flex-end;"
            >
            <a-upload
              accept=".png,.jpg"
              :showUploadList="false"
              :before-upload="beforeUpload"
              :capture="null"
              >
              <CloudUploadOutlined class="up-icon" />
            </a-upload>
            <span class="cancel-button" @click="replyId = null" v-if="breakpoint > breakpointList.xs">{{$t('moment.cancel')}}</span>
            <a-button type="primary" @click="onComment({moment: currentItem, momentId: currentItem.momentId, context: replyWord, commentId: comment.commentId, fileList})">
              <!-- {{$t('moment.reply')}}<span v-if="breakpoint > breakpointList.xs">{{$t('moment.comment')}}</span> -->
              {{`${$t('moment.reply')} ${breakpoint > breakpointList.xs ? $t('moment.comment') : ''}`}}
            </a-button>
          </div>
        </div>
        <div
          v-if="fileList && fileList.length && replyId === comment.commentId"
          class="flex flex-wrap flex-a-i-center">
          <div v-for="(cImg, imgdx) in fileList" :key="imgdx" style="position: relative;">
            <a-image
              style="object-fit:cover;"
              :width="110"
              :src="cImg.path"
            >
            </a-image>
            <CloseCircleOutlined style="font-size: 20px;color: red;cursor: pointer;position: absolute;top: 0;right: 0;" @click="delmg(imgdx)"/>
          </div>

        </div>
        <div class="card-divider" v-if="replyId === comment.commentId && breakpoint > breakpointList.xs"/>
        <template #datetime>
          <span>{{$utils.toTime(comment.created)}}</span>
        </template>
        <template #content>
          <p><span style="word-break: break-all;" v-if="comment.toUserId">{{`${$t('moment.reply')}：`}}<span style="color: #008FFC;">@{{getUserName(comment.toUserId)}}：</span></span>{{comment.context}}</p>
          <div
            v-if="comment.attachments && comment.attachments.length"
            class="flex flex-wrap flex-a-i-center">
            <a-image
              style="object-fit:cover;"
              :width="110"
              v-for="(cImg, imgdx) in comment.attachments" :key="imgdx"
              :src="cImg.path"
            />
          </div>
        </template>
      </a-comment>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import {
  SendOutlined,
  MessageFilled,
  LikeFilled,
  CloudUploadOutlined,
  CloseCircleOutlined
  // EllipsisOutlined
} from '@ant-design/icons-vue'
import api from '@/services/api'
// import { useStore } from 'vuex'
import { mapState, useStore } from 'vuex'
import { mergeArrayObjects, filetoBase64, extractColorByName } from '@/utils/utils.js'
import SparkMD5 from 'spark-md5'
import config from '@/config'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'CardDetail',
  components: {
    SendOutlined,
    MessageFilled,
    LikeFilled,
    CloudUploadOutlined,
    CloseCircleOutlined
    // EllipsisOutlined
  },
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    currentItem: {
      type: Object,
      default: () => {}
    },
    isActionShow: {
      type: Boolean,
      default: true
    },
    isCommentShow: {
      type: Boolean,
      default: false
    },
    imgMaxLength: {
      type: Number,
      default: 6
    }
  },
  computed: {
    ...mapState([
      'breakpoint',
      'breakpointList'
    ])
  },
  setup (props, context) {
    const state = reactive({
      commentList: [

      ],
      replyId: null,
      replyWord: '',
      commentWord: '',
      userList: [],
      isFocus: false,
      actionUrl: '',
      upHeaders: {},
      upMethod: 'PUT',
      ossObj: null,
      fileList: []
    })

    const store = useStore()
    const { t } = useI18n()

    onMounted(async () => {
      console.log('store.state.breakpoint' + store.state.breakpoint)
    })
    const delmg = (idx) => {
      state.fileList = state.fileList.slice(idx, 0)
    }

    const resetCommentTex = () => {
      state.replyWord = ''
      state.commentWord = ''
      state.replyId = null
      state.fileList = []
      state.actionUrl = ''
      state.upHeaders = {}
      state.upMethod = 'PUT'
      state.ossObj = null
    }

    // 获取oss url
    const upload = async (type, hash, file) => {
      try {
        const res = await api.moment.getOssUrl({
          type,
          hash,
          origin: '4',
          module: 'COMMENT'
        })
        if (res.code === 200) {
          const data = res.data
          state.actionUrl = data.uploadUrl
          state.upHeaders = data.headers
          state.upMethod = data.method
          state.ossObj = data
          const result = await fetch(state.actionUrl, {
            headers: state.upHeaders,
            method: state.upMethod,
            body: file
          })
          if (result.status === 200) {
            state.upHash = hash
            state.fileList = [{
              hash: hash,
              aliasName: file.name,
              path: config.PHOTO_APP_IMG_BASE_URL + state.ossObj.path,
              file
            }]
          }
        } else if (res.code === 20005) {
          filetoBase64(file, (url) => {
            state.fileList = [{
              hash: hash,
              aliasName: file.name,
              path: url,
              file
            }]
          })
        }
      } catch (error) {

      }
    }

    // 获取评论列表
    const getCommentList = async (momentId) => {
      if (!momentId) return
      try {
        const res = await api.moment.getCommentList({
          momentId,
          projectId: props.currentItem.projectId,
          orgId: props.currentItem.orgId
        })
        if (res.code === 200) {
          state.userList = res.data.users
          state.commentList = mergeArrayObjects(res.data.list.map(item => {
            return {
              ...item,
              userId: item.createUserId
            }
          }), res.data.users, 'userId')
          console.log(state.commentList)
        }
      } catch (error) {
        console.error(error)
      }
    }

    // 获取用户名
    const getUserName = (userId) => {
      const name = state.userList?.find(item => item.userId === userId)?.nickName
      return name || ''
    }

    const setVisible = (idx) => {
      context.emit('setVisible', idx, props.currentItem)
    }

    const replyTo = (item) => {
      resetCommentTex()
      state.replyId = item.commentId
    }

    const commentClick = () => {
      context.emit('commentClick', props.currentItem)
    }

    const tagClick = (id, name) => {
      context.emit('tagClick', id, name)
    }

    const onComment = (data) => {
      context.emit('comment', data)
    }

    const beforeUpload = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        const spark = new SparkMD5() // 创建md5对象（基于SparkMD5）
        if (file.size > 1024 * 1024 * 10) {
          const data1 = file.slice(0, 1024 * 1024 * 10) // 将文件进行分块 file.slice(start,length)
          fileReader.readAsBinaryString(data1) // 将文件读取为二进制码
        } else {
          fileReader.readAsBinaryString(file)
        }

        // 文件读取完毕之后的处理
        // a639e28526d1809745b46bf1189594fe  6d9efe0c593b1383482feb229318e03a
        fileReader.onload = async (e) => {
          spark.appendBinary(e.target.result)
          const md5 = spark.end()
          console.log(md5, file)
          await upload(file.type.split('/')[1]?.toUpperCase(), md5, file)
          // resolve(file)
        }
      })
    }

    const likeMoment = () => {
      context.emit('likeMoment', props.currentItem)
    }

    const jumpLink = (momentLink) => {
      const link = momentLink.link.split('|')[1]
      if (momentLink.target === 'open') {
        window.open(link, '_self')
      } else if (momentLink.target === 'href') {
        window.open(link, '_blank')
      }
    }

    const deleteMoment = async (id) => {
      try {
        const res = await api.moment.deleteMoment({
          momentId: id
        })
        if (res.code === 200) {
          message.success(t('moment.deleteSuccess'))
          context.emit('deleteSuccess')
        }
      } catch (error) {
        console.error(error)
      }
    }

    watch(() => props.currentItem, () => {
      resetCommentTex()
    }, {
      deep: true,
      immediate: true
    })

    return {
      ...toRefs(state),
      setVisible,
      replyTo,
      commentClick,
      tagClick,
      onComment,
      getCommentList,
      getUserName,
      beforeUpload,
      delmg,
      likeMoment,
      extractColorByName,
      jumpLink,
      deleteMoment
    }
  }
})
</script>
<style lang="less">
  .card-item .ant-image {
    margin: 8px 8px 0 0;
  }
</style>
<style lang='less' scoped>
.card-item {
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
  .max-img {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    position: absolute;
    left: 8px;
    top: 4px;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
  .card-header {
      justify-content: space-between;
      display: flex;
      margin-bottom: 16px;
      .header-project {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0,0,0,0.4);
        font-weight: 600;
      }
      .header-avatar {
          margin-right: 16px;
      }
      .header-info {
        .info-name {
            font-size: 18px;
            font-weight: 600;
        }
        .info-date {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
        }
      }
  }
  .card-img-list {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      .img-three-part {
        padding-top: 33.3%;
      }
      .img-two-part {
        padding-top: 50%;
      }
      .img-item {
        width: 100%;
        max-width: 225px;
        max-height: 300px;
        object-fit: cover;
        cursor: pointer;
        padding: 4px;
        top: 0;
      }
      .mut-img {
        height: 100%;
        position: absolute;
      }
  }
  .card-title {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: normal;
  }
  .card-link {
    color: #007AFF;
    cursor: pointer;
  }
  .card-tag {
      margin-bottom: 16px;
      .tag-item {
          font-size: 16px;
          color: rgba(0, 143, 252, 100);
          cursor: pointer;
      }
      .tag-item + .tag-item {
        margin-left: 8px;
      }
  }
  .card-action {
      display: flex;
      justify-content: space-between;
      .action-button {
          height: 32px;
          display: flex;
          align-items: center;
          padding: 0 14px;
          border-radius: 4px;
          background-color: rgba(246, 248, 250, 1);
          color: rgba(161, 165, 183, 100);
          cursor: pointer;
          &.button-active {
            background: #E8F3FF;
            color: #007AFF;
          }
      }
      .action-button + .action-button {
        margin-left: 8px;
      }
  }
  .card-divider {
    border-top: solid 1px rgba(239, 242, 245, 1);
    margin: 16px 0
  }
  .card-comment {
      display: flex;
      .cancel-button {
        margin: 0px 16px;
        white-space: nowrap;
        align-items: center;
        display: flex;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
      }
  }

  .next-row {
    flex-direction: column;
    /* justify-content: flex-end; */
  }
  .card-comment-list {
    .comment-author {
      color: rgba(0, 0, 0, 1);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .up-icon {
    font-size: 22px;
    line-height: 32px;
    margin: 2px 16px 0 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6)
  }
}

</style>
