<!--    消息列表弹窗   -->
<template>
  <a-layout style="display: none;">
    <a-layout-sider
      :width="0"
      class="client-layout-sider"
      breakpoint="lg"
      collapsed-width="0"
      @breakpoint="breakpointAction">
    </a-layout-sider>
  </a-layout>

  <a-modal
    v-model:visible="$store.state.isMessageModalShow"
    :class="{'full-modal': isBreakpoint}"
    destroyOnClose
    :title="$t('moment.message')"
    :width="isBreakpoint ? '100%' : '60%'"
    maskClosable
    :footer="null"
    centered
    :bodyStyle="{'max-height': isBreakpoint ? 'max-content' : '70vh', 'overflow-y': 'auto'}">
    <a-tabs @change="typeTabsChange">
      <a-tab-pane :tab="$t('moment.moment')" :key="0">
        <a-tabs @change="tabsChange">
          <a-tab-pane :key="0" :tab="`${$t('moment.all')}(${messageNum.total || 0})`">
            <MessageList
              :data="messageList[0]"
              @clickMessage="clickMessage"/>
          </a-tab-pane>
          <a-tab-pane :key="3" :tab="`${$t('moment.comment')}(${messageNum[3] || 0})`">
            <MessageList
              :data="messageList[3]"
              @clickMessage="clickMessage"/>
          </a-tab-pane>
          <a-tab-pane :key="4" :tab="`${$t('moment.reply')}(${messageNum[4] || 0})`">
            <MessageList
              :data="messageList[4]"
              @clickMessage="clickMessage"/>
          </a-tab-pane>
          <a-tab-pane :key="5" :tab="`${$t('moment.like')}(${messageNum[5] || 0})`">
            <MessageList
              :data="messageList[5]"
              @clickMessage="clickMessage"/>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
      <a-tab-pane tab="Note" :key="1">
        <CommentList
          :data="noteMessageList"
          @onRowClick="onRowClick"
        />
      </a-tab-pane>
    </a-tabs>
  </a-modal>

  <a-modal
    v-model:visible="messageDetailVisible"
    :class="{'full-modal': isBreakpoint}"
    destroyOnClose
    :width="isBreakpoint ? '100%' : '50%'"
    :title="$t('moment.momentDetail')"
    maskClosable
    :footer="null"
    centered
    :bodyStyle="{'max-height': isBreakpoint ? 'max-content' : '70vh', 'overflow-y': 'auto'}">
    <card-detail
      ref="messageDetailRef"
      :photos="messageDetail.photos"
      @setVisible="setVisible"
      :isActionShow="false"
      isCommentShow
      :currentItem="messageDetail"
      @comment="comment($event, true)"
      @likeMoment="likeMoment"
      @deleteSuccess="refresh(); messageDetailVisible = false"/>
  </a-modal>
</template>

<script setup>
import { nextTick, ref } from 'vue'
import api from '@/services/api'
import MessageList from '@/components/moment/MessageList'
import CommentList from '@/components/moment/message/CommentList'
import { useRouter } from 'vue-router'
import CardDetail from '@/components/moment/CardDetail.vue'

const noteMessageList = ref()
const messageList = ref({})
const messageNum = ref({})

const messageDetailRef = ref()
const isBreakpoint = ref(false)
const messageDetail = ref({})
const messageDetailVisible = ref()

const router = useRouter()

const getMessageList = async (actionType = 0, notifyType = 0) => {
  const res = await api.moment.getMessageList({
    actionType: actionType || null,
    notifyType: notifyType,
    page: 1,
    size: 200
  })
  if (res.code === 200) {
    const { list, users } = res.data
    return list.map(i => {
      const user = users.find(u => i.createUserId === u.userId)
      if (user) {
        i.createdUser = user
      } else {
        i.createdUser = {}
      }
      const toUser = users.find(u => i.toUserId === u.userId)
      if (user) {
        i.toUser = toUser
      } else {
        i.toUser = {}
      }
      return i
    })
  }
}

const getMessageNum = async () => {
  const res = await api.moment.getMessageNum()
  if (res.code === 200) {
    if (res.data.noticeCategories) {
      res.data.noticeCategories.map(i => {
        res.data[i.typeId] = i.count
      })
      messageNum.value = res.data
    }
  }
}

getMessageNum()

const typeTabsChange = async (e) => {
  if (e === 1) {
    noteMessageList.value = await getMessageList(null, e)
    console.log(noteMessageList.value)
  }
}

const tabsChange = async (e) => {
  console.log('i am tabsChange', e)
  messageList.value[e] = await getMessageList(e)
}

tabsChange(0)

const contractMoment = (data) => {
  const { hashtagMate, list, users, likeMomentIds, projects } = data
  if (!hashtagMate || !list || !users) return []
  if (!list || !list.length) return []
  return list.map(i => {
    const user = users.find(k => k.userId === i.createUserId)
    const { hashtags, mates } = hashtagMate
    // if (!hashtags || !mates) return
    const tags = (mates || []).filter(j => j.momentId === i.momentId)
    const tagList = tags.map(k => {
      return (hashtags || []).find(j => j.tagId === k.tagId)
    })

    let projectName = null
    if (projects && projects.length) {
      const project = projects.find(l => i.projectId === l.projectId)
      if (project) {
        projectName = project.projectName
      }
    }
    const isLike = likeMomentIds?.find(l => l === i.momentId)
    return {
      momentId: i.momentId,
      nickName: user?.nickName,
      createdDate: i.created,
      photos: i.photos,
      detailTitle: i.comment,
      tagList: tagList,
      likeQty: Number(i.likeQty),
      commentQty: i.commentQty,
      headAvatar: user?.headAvatar,
      isLike: !!isLike,
      projectName,
      orgId: i.orgId,
      projectId: i.projectId,
      momentLink: i.momentLink,
      isTop: i.isTop,
      createUserId: i.createUserId
    }
  })
}

const clickMessage = async (id) => {
  try {
    const res = await api.moment.getMomentDetail({
      momentId: id
    })
    if (res.code === 200) {
      console.log(res)
      const list = [res.data]
      const users = [res.data.user]
      const projects = [res.data.project]
      const hashtagMate = res.data.hashtagMate
      const data = {
        hashtagMate,
        list,
        users,
        projects
      }
      messageDetail.value = contractMoment(data)[0]
      messageDetailVisible.value = true
      nextTick(() => {
        if (messageDetailRef.value && messageDetailRef.value.getCommentList) messageDetailRef.value.getCommentList(id)
      })
    }
  } catch (error) {
    console.error(error)
  }
}

const breakpointAction = (e) => {
  isBreakpoint.value = e
}
</script>

<style lang='less'>
.full-modal {
  &&.ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
